import styled from 'styled-components';

import themeSection from '@styles/includes/themeSection';

export const Anchor = styled.a`
  ${themeSection}
  display: flex;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  @media ${(props) => props.theme.breakpoints.sm} {
    border: solid 3px var(--theme-color-text);
    box-shadow: 6px 6px 0 0 var(--theme-color-section);
    display: block;
    margin-bottom: 2rem;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 100%;
    }
  }
`;

export const ImageContainer = styled.div`
  height: 140px;
  position: relative;
  width: 140px;

  @media ${(props) => props.theme.breakpoints.sm} {
    height: 100%;
    position: absolute;
    top: 0;
    transform: scale(1);
    transition: transform 200ms;
    width: 100%;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const Text = styled.div`
  background: var(--theme-color-background);
  border-top: solid 2px var(--theme-color-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;

  @media ${(props) => props.theme.breakpoints.sm} {
    border-top: 0;
    display: block;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 0;
  }
`;

export const Title = styled.h1`
  color: var(--theme-color-alwaysLight);
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: -0.02rem;
  line-height: 1.4;
  margin-bottom: 0.5rem;

  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 1.4rem;
  }
`;

export const Background = styled.div`
  @media ${(props) => props.theme.breakpoints.sm} {
    background: var(--theme-color-always-dark-overlay);
    bottom: 0;
    color: var(--theme-color-always-light);
    left: 0;
    margin-bottom: 0;
    padding: 0.5rem 1rem;
    position: absolute;
    width: 100%;
  }
`;

export const Date = styled.time`
  color: var(--theme-color-grey3);
  display: block;
  font-weight: 600;
  letter-spacing: -0.02rem;
  margin-bottom: 0.5rem;
  @media ${(props) => props.theme.breakpoints.sm} {
    color: var(--theme-color-always-light);
    text-align: right;
  }
`;

export const Section = styled.h2`
  color: var(--theme-color-grey3);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: -0.02rem;
  margin-bottom: 0;

  @media ${(props) => props.theme.breakpoints.sm} {
    background: var(--theme-color-always-dark-overlay);
    color: var(--theme-color-always-light);
    display: inline-block;
    font-weight: 600;
    left: 0;
    padding: 0.5rem 1rem;
    position: absolute;
    top: 0;
  }
`;
