import styled from 'styled-components';

export const Wrapper = styled.section`
  margin-bottom: 2rem;
  padding: 0 1rem;
`;

interface ContainerProps {
  colSize: number;
}

export const Container = styled.div<ContainerProps>`
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr;

  @media ${(props) => props.theme.breakpoints.sm} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: ${(props) =>
      Array.from({ length: props.colSize }, () => '1fr').join(' ')};
  }
`;

export const EntryBox = styled.div<ContainerProps>`
  @media ${(props) => props.theme.breakpoints.sm} {
    &:first-child {
      grid-column: ${(props) => (props.colSize === 3 ? 'span 2' : 'initial')};
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    &:first-child {
      grid-column: initial;
    }
  }
`;

export const MoreLink = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  text-align: right;
`;
export const Anchor = styled.a`
  text-decoration: none;
`;
