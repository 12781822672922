import { memo } from 'react';
import Image from "next/legacy/image";
import Link from 'next/link';

import useWindowDimensions from '@hooks/useWindowDimensions';

import * as S from './EntryBox.sc';

type EntryBoxProps = {
  date: string;
  image: {
    blur: string;
    full: string;
    mobile: string;
  };
  section: Section;
  slug: string;
  title: string;
};

const EntryBox = ({ date, image, section, slug, title }: EntryBoxProps) => {
  const { width } = useWindowDimensions();

  return (
    <Link href={slug} passHref legacyBehavior>
      <S.Anchor data-section={section.toLowerCase()}>
        <S.ImageContainer>
          <Image
            alt=""
            blurDataURL={image.blur}
            layout="fill"
            placeholder="blur"
            src={width && width > 640 ? image.full : image.mobile}
            unoptimized={true}
          />
        </S.ImageContainer>
        <S.Text>
          <S.Background>
            <S.Title>{title}</S.Title>
            <S.Date dateTime={date}>
              {new Date(date).toLocaleString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </S.Date>
          </S.Background>
          <S.Section>{section}</S.Section>
        </S.Text>
      </S.Anchor>
    </Link>
  );
};

export default memo(EntryBox);
