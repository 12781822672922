import Link from 'next/link';
import * as S from './ContentList.sc';

import EntryBox from '@components/ContentList/EntryBox/EntryBox';

type ContentListProps = {
  moreLink?: {
    text: string;
    url: string;
  };
  limit?: Boolean;
  posts: Array<any>;
  size?: 2 | 3 | 4;
};

const ContentList = ({
  moreLink,
  limit,
  posts,
  size = 4,
}: ContentListProps) => {
  if (!posts || posts.length === 0) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.Container colSize={size}>
        {posts.slice(0, limit ? size : 1000).map((post) => {
          const slug = `/${post.section.toLowerCase()}/${post.slug}`;
          return (
            <S.EntryBox colSize={size} key={slug}>
              <EntryBox
                date={post.date}
                image={post.hero}
                section={post.section}
                slug={slug}
                title={post.title}
              />
            </S.EntryBox>
          );
        })}
      </S.Container>
      {moreLink ? (
        <S.MoreLink>
          <Link href={moreLink.url} passHref legacyBehavior>
            <S.Anchor>{moreLink.text} &raquo;</S.Anchor>
          </Link>
        </S.MoreLink>
      ) : null}
    </S.Wrapper>
  );
};

export default ContentList;
